<template>
  <section>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'principes' })" />
        </div>
      </div>

      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier un refCard</p>
          <b-button variant="secondary">Confirmer</b-button>
          <hr class="w-100" />
        </div>

        <!-- inputs -->
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group label-cols="12" label="Libellé" label-class="font-weight-bold">
              <b-form-input type="text" value="test"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea rows="5" value="Loremp loremp loremp "></b-form-textarea>
            </b-form-group>
            <b-form-group label-cols="12" label="Code" label-class="font-weight-bold">
              <b-form-input type="text" value="Code-51"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Type RefCard"
              label-class="font-weight-bold"
            >
              <b-form-select type="text"></b-form-select>
            </b-form-group>
            <b-form-group label-cols="12" label="Statut" label-class="font-weight-bold">
              <b-form-select type="text" :options="['Validé', 'Deployé']"></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Redacteur"
              label-class="font-weight-bold"
            >
              <b-form-select
                type="text"
                :options="['Karly Vequaud', 'Hal Dobrovolski']"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="12"
              label="Capacité technique"
              label-class="font-weight-bold"
            >
              <b-form-select
                :options="['Cap technique 1', 'Cap technique 2', 'Cap technique 3']"
                type="text"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Contributeurs"
              label-class="font-weight-bold"
            >
              <b-form-select
                type="text"
                :options="['Architecte', 'Sponsor']"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols="12" label="Type" label-class="font-weight-bold">
              <b-form-select
                type="text"
                :options="['Pattern', 'Régle', 'Politique']"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Applications"
              label-class="font-weight-bold"
            >
              <b-form-input type="text" value="Application"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Types systémes"
              label-class="font-weight-bold"
            >
              <b-form-select type="text" value="Système d'application"></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Systemes d'applications"
              label-class="font-weight-bold"
            >
              <b-form-select type="text" value="Système d'application"></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Joindre un fichier"
              label-class="font-weight-bold"
            >
              <b-form-file
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="Choissez un fichier ou faites glissez"
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
        </div>
        <!-- end inputs -->
      </div>

      <!-- End Tabs -->
    </b-card>
  </section>
</template>

<script>
// import ModalActions from "@/components/custom/ModalActions.vue";
//import Cadres from "./components/Cadres.vue";
//import Implementation from "./components/Implementations.vue";
export default {
  components: {},
  data: () => ({
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          implementaion: 4,
          livrable: 2,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacite", label: "Capacite technique", sortable: true },
        { key: "implementaion", label: "Implémentaion", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "livrable", label: "Livrables", sortable: true },
        { key: "actions", label: "" },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type object", sortable: true },
        { key: "libelle", label: "Libellé object", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "patrimoine", label: "Implémentaion", sortable: true },
        { key: "actions", label: "" },
      ],
    },
  }),
};
</script>
